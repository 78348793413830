<template>
  <div class="int">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item prop="loginname">
        <el-input
          v-model="ruleForm.loginname"
          placeholder="账号"
          :onkeyup="
            (ruleForm.loginname = ruleForm.loginname.replace(/\s+/g, ''))
          "
          class="intl"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="ruleForm.password"
          type="password"
          :onkeyup="(ruleForm.password = ruleForm.password.replace(/\s+/g, ''))"
          placeholder="密码"
          style="margin-bottom: 10px"
        ></el-input>
      </el-form-item>

      <el-radio-group v-model="ruleForm.radio" class="radio" @change="aa">
        <el-radio :label="1">作者</el-radio>
        <el-radio :label="2">期刊</el-radio>
        <el-radio :label="3">服务商</el-radio>
      </el-radio-group>
      <el-button
        type="danger"
        style="width: 300px; margin-bottom: 20px"
        @click="Denglu(ruleForm)"
        >登录</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { DengLogin } from "../../API/login";
export default {
  data() {
    return {
      ruleForm: {
        loginname: "", //账号
        password: "", // 密码
        radio: "", // 编号 1作者登录 2期刊端登录 3服务商登录
      },
      rules: {
        loginname: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    var _self = this;
    document.onkeydown = function (e) {
      if (window.event == undefined) {
        var key = e.keyCode;
      } else {
        var key = window.event.keyCode;
      }
      if (key == 13) {
        _self.Denglu("ruleForm");
      }
    };
  },
  methods: {
    aa(e) {
      this.radio = e;
      console.log(this.radio);
    },

    Denglu(formName) {
      if (this.ruleForm.radio == "") {
        this.$message.error("请选择身份");
      } else {
        DengLogin(
          this.ruleForm.radio,
          this.ruleForm.loginname,
          this.ruleForm.password
        ).then((res) => {
          console.log(res);
          localStorage.setItem("id", res.data.data);
          localStorage.setItem("code", res.data.code);
          localStorage.setItem("total", res.data.total);
          sessionStorage.setItem("sid", res.data.data);
          sessionStorage.setItem("scode", res.data.code);
          if (res.data.code == 1) {
            sessionStorage.setItem("identity", this.ruleForm.radio);
            localStorage.setItem("FuWuId", res.data.data);
            //判断选中之后存入缓存里
            this.$message({
              type: "info",
              message: "登录成功",
              type: "success",
            });
            // this.$router.push("/Services");
            if (res.data.isFirst == 1) {
              this.$router.push("/homePage?id=fuwu");
            } else {
              this.$router.push("/homePage");
            }
          } else if (res.data.code == 2) {
            sessionStorage.setItem("identity", this.ruleForm.radio);
            this.$message({
              type: "info",
              message: "登录成功",
              type: "success",
            });
            // this.$router.push("/Staff");

            this.$router.push("/homePage");
            localStorage.setItem("UserId", res.data.data);
          } else if (res.data.code == 3) {
            sessionStorage.setItem("identity", this.ruleForm.radio);
            this.$message({
              type: "info",
              message: "登录成功",
              type: "success",
            });
            // this.$router.push("/Periodical");
            if (res.data.isFirst == 1) {
              this.$router.push("/homePage?id=QK");
            } else {
              this.$router.push("/homePage");
            }
            localStorage.setItem("QKId", res.data.data);
          } else if (res.data.code == 0) {
            sessionStorage.setItem("identity", this.ruleForm.radio);
            this.$message({
              type: "info",
              message: "登录成功",
              type: "success",
            });
            // this.$router.push("/Pending");
            if (res.data.isFirst == 1) {
              this.$router.push("/homePage?id=ZZ");
            } else {
              this.$router.push("/homePage");
            }
            localStorage.setItem("ZZId", res.data.data);
          } else {
            this.$message.error("登录失败");
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.int {
  width: 300px;
  height: 220px;
  margin: 20px auto 0 auto;
}

.btn {
  width: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio {
  width: 300px;
  height: 20px;
  margin: 0 auto;
  text-align: center;
}
</style>
