<template>
	<div class="loginStyle">
		<div class="logo">
			<router-link to="/"><img src="../../assets/image/logo.png" alt="Logo" style="width: 143px;height: 61px;"></router-link>
		</div>
		<div>
			<img class="loginwen" src="../../assets/image/wenzi.png" />
		</div>
		<div class="hezi">
			<el-form class="loginContainer">
				<div class="btnList">
					<span v-for="(item , index) in datalist" style="cursor: pointer;" :key="index" @click="showThis(index)" class='btnStatus' :class="{loginactive: index ===  tab}">{{item.val}}</span>
					<!-- <span @click="showThis(1)" class='btnStatus' :class="{active:1}">账号密码登录</span> -->
				</div>
				<div style="width: 380px; height: auto; ">
					<template v-if="tab == 0">
						<ZhuCe></ZhuCe>
					</template>
					<template v-if="tab == 1">
						<Denglu></Denglu>
					</template>
					<div class="foot">
						注册手机验证后自动登录
					</div>
					<!-- <div class="foot" style="cursor: pointer;">
						注册即代表同意<b style="color: #0000FF;" @click="heretofore">《刊大师服务条款》</b>
					</div> -->
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
	import ZhuCe from './zhuce.vue'
	import Denglu from './denglu.vue'
	export default {
		name: 'Login',
		components: {
			ZhuCe,
			Denglu
		},
		data() {
			return {
				tab: 0, // 默认手机页面
				datalist: [{
						val: "手机验证码登录"
					},
					{
						val: "账号密码登录"
					}
				]
			}
		},
		methods: {
			//页面上面的按钮的点击事件
			showThis(key, index) {
				this.tab = index
				this.tab = key
			},
			// 点击跳转服务条款页
			heretofore() {
				this.$router.push({
					path: '/hereof',
				});
			}
		},
	}
</script>

<style>
	body {
		margin: 0;
		padding: 0;
	}

	.loginStyle {
		
		background: url(../../assets/image/beijing.jpg) center center no-repeat;
		background-size:cover;
		height: 100%;
		width: 100%;
		position: fixed;
		top: 0;
		bottom: 0;
		
	}

	.loginwen {
		width: 527px;
		height: 89px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: 100px auto 0 auto;

	}

	.btnList {
		width: 280px;
		height: 30px;
		font-size: 14px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #141414;
		margin: 30px;
		line-height: 20px;
	}

	.btnList span {
		margin-left: 10px;
	}

	.loginContainer {
		position: fixed;
		width: 380px;
		height: 380px;
		display: flex;
		flex-direction: column;
		background: #FFFFFF;
		border-radius: 10px;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		background-color: #ffffff;
	}

	.logo {
		position: absolute;
		top: 30px;
		width: 143px;
		height: 61px;
		margin-left: 30px;
	}

	.loginTitle {
		margin: 15px auto 20px auto;
		text-align: center;
		color: #505458;
	}

	.loginRemember {
		text-align: left;
		margin: 0px 0px 15px 0px;
	}

	.btnList {
		margin-bottom: 12px;
	}

	.hezi {
		width: 380px;
		height: 400px;
	}

	.btnStatus {
		background: #FFFFFF;
		border-radius: 4px;
		border: none;
		color: #999999;
		font-size: 16px;
		outline: none;
		min-width: 112px;
		height: 46px;
	}

	.btnStatus.loginactive {
		color: #333333;
	}

	.foot {
		height: 25px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		line-height: 17px;
		text-align: center;
		position: relative;
		margin: 0 auto;

	}
</style>
