<template>
  <div class="int">
    <el-form
      size="mini"
      inline
      label-position="right"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
    >
      <el-input
        v-model="ruleForm.phoneNum"
        placeholder="手机号"
        class="intl"
      ></el-input>
      <el-input v-model="ruleForm.phonecode" placeholder="验证码"
        ><i
          v-if="able"
          slot="suffix"
          style="
            font-style: normal;
            position: relative;
            top: 10px;
            right: 20px;
            color: red;
            cursor: pointer;
          "
          @click="sendcode"
          >{{ btnContent }}</i
        ><i
          v-else
          slot="suffix"
          style="
            font-style: normal;
            position: relative;
            top: 10px;
            right: 20px;
            color: red;
            cursor: pointer;
          "
          >{{ btnContent }}</i
        >
      </el-input>

      <el-button
        type="danger"
        class="btntype"
        @click="registerorlogin(ruleForm)"
        >注册/登录</el-button
      >
    </el-form>
  </div>
</template>

<script>
import { doLogin, phoneLogin, zuophone } from "../../API/login";

export default {
  data () {
    return {
      ruleForm: {
        phoneNum: "", // 手机input的值
        phonecode: "", // 验证码
      },
      input: "", // 手机号


      btnContent: "获取验证码",
      verifyNum: "", // 验证码的input 的值
      time: 0, // 发送验证码间隔时间
      disabled: false, // 按钮状态
      errors: {}, //验证提示信息
      able: true,
      rules: {
        phoneNum: [{ required: true, message: "请输入手机号", trigger: "blur" }],
        phonecode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  //在用户输入验证码时我需要监听用户输入状态的长度
  watch: {
    verifyNum (curVal, oldVal) {
      if (curVal.length === 6) {
        // 判断它的长度是否等于6
        this.verifyNum = curVal; //让最新的值赋给验证码input
        this.sendcodeItem(); //然后在请求后台的登陆接口
      }
    },
  },
  methods: {
    //按钮时就需要校验手机号的输入格式了，触发一个点击事件
    sendcode () {
      let reg = 11 && /^((13|14|15|17|18||16||19)[0-9]{1}\d{8})$/; // 手机号正则验证
      let phoneNum = this.ruleForm.phoneNum; // 输入手机号的value
      if (!phoneNum) {
        //
        // 未输入手机号
        this.$message.error("请输入11位手机号");
        return;
      }
      if (!reg.test(phoneNum)) {
        // 手机号不合法
        this.$message.error("手机号不合法，请重新输入");
        // this.phoneNum = '' // 这个看情况 ，看是否要删除，获取让用用户手机清除
      } else {
        let time = 60; //判断 时间为60s
        this.btnContent = time + "秒后重新发送";
        let timer = setInterval(() => {
          if (time === 0) {
            //  当时间等于 0的时候
            clearInterval(timer); //清除时间
            this.disabled = false; // 让按钮的样式 为登陆状态
            this.btnContent = "重新获取验证码"; // 登陆状态的value文本
            this.verifyNum = ""; // 清除掉验证表单的值
            this.able = true;
          } else {
            this.btnContent = time + "秒后重新发送"; // 若时间大于则展示重新获取
            this.disabled = true; // 判断显示文本的状态
            this.able = false;
            time--; //将时间的一秒秒
          }
        }, 1000);
      }
      // login 是后台的请求接口 将手机号传给后台
      phoneLogin(this.ruleForm.phoneNum).then((res) => {
        // 此处写后台接口 获取到后台的返回参数来决定

        console.log(res);
        if (res.data.code == 0) {
          this.$message.success("发送成功");
        } else {
          this.$message.error("发送失败");
        }
      });
    },
    registerorlogin () {
	  console.log(this.$route.query,"参数参数参数参数参数参数参数参数参数")
       if (this.$route.query.num) {
        this.ruleForm.identy = this.$route.query.num
      } else if (this.$route.query.F) {
        this.ruleForm.identy = this.$route.query.F
      } else {
        this.ruleForm.identy = this.$route.query.S
      }
      console.log(this.ruleForm.identy, 'a')
      if (this.ruleForm.phoneNum == "") {
        this.$message.error("请输入手机号");
      } else if (this.ruleForm.phonecode == "") {
        this.$message.error("请输入正确的验证码");

      } else {
        zuophone(
          this.ruleForm.phoneNum,
          this.ruleForm.phonecode,
          this.ruleForm.identy
          // this.$route.query.num ? this.$route.query.num : "",
          // this.$route.query.F ? this.$route.query.F : "",
          // this.$route.query.S ? this.$route.query.S : ""
        ).then((res) => {
          console.log(res.data.data);
          localStorage.setItem("id", res.data.data);
          localStorage.setItem("iphone", res.data.total);
          localStorage.setItem("total", res.data.total);
          console.log('total', localStorage.getItem("total"));
          sessionStorage.setItem("sid", res.data.data);
          if (res.data.code == -1) {
            this.$message.error(res.data.msg);
          }else if (res.data.code == 33) {
            this.$message.success("服务商验证通过");
            localStorage.setItem("FuWuId", res.data.data);
            localStorage.setItem("code", 1);
            sessionStorage.setItem("scode", 1);
            this.$router.push("/Services");
          } else if (res.data.code == 22) {
            this.$message.success("员工验证通过");
            this.$router.push("/Staff");
            localStorage.setItem("UserId", res.data.data);
            sessionStorage.setItem("scode", 2);
            localStorage.setItem("code", 2);
          } else if (res.data.code == 34) {
            this.$message.success("期刊验证通过");
            this.$router.push("/Periodical");
            localStorage.setItem("QKId", res.data.data);
            sessionStorage.setItem("scode", 3);
            localStorage.setItem("code", 3);
          } else {
            this.$router.push("/Pending");
            localStorage.setItem("ZZId", res.data.data);
            this.$message.success("作者注册登陆成功");
            sessionStorage.setItem("scode", 0);
            localStorage.setItem("code", 0);
          }
        });
      }



    },
  },
};
</script>

<style scoped>
.int {
  width: 300px;
  height: 200px;
  margin: 20px auto;
}

.intl {
  margin-top: 10px;
  margin-bottom: 30px;
}

.btntype {
  width: 300px;
  margin-top: 25px;
  margin-bottom: 50px;
}
</style>
