//login.js
import request from '@/utils/request'
import qs from 'qs'

// 手机注册
export function doLogin (phone, phonecode) {
  let data = {
    phone,
    phonecode
  }
  data = qs.stringify(data)
  return request({
    url: '/loginc/zuophone',
    method: 'post',
    data
  })
}
// 获取验证码
export function phoneLogin (phone,) {
  let data = {
    phone,
  }
  data = qs.stringify(data)
  return request({
    url: '/loginc/yanzhengma',
    method: 'post',
    data
  })
}
// 账号登录
export function DengLogin (state,loginname,password) {
  let data = {
    state,loginname,password
  }
  data = qs.stringify(data)
  return request({
    url: '/loginc/login',
    method: 'post',
    data
  })
}

//手机注册登录
export function zuophone (phone,phonecode,invitation_code) {
  let data = {
   phone,phonecode,invitation_code
  }
  data = qs.stringify(data)
  return request({
    url: '/loginc/zuophone',
    method: 'post',
    data
  })
}